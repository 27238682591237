<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-3 mb-3 border border-secondary">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-2 pr-0 text-right">
              <label class="col-form-label">Paciente:</label>
            </div>
            <div class="col-sm-10">
              <multiselect v-model="pacienteSelected"
                :options="pacientes"
                placeholder=""
                label="full_name"
                track-by="full_name"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(pacienteSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-3 pr-0 text-right">
              <label class="col-form-label">Parentesco:</label>
            </div>
            <div class="col-sm-8">
              <b-form-select v-model="parentescoSelected"
               :options="getParentescos">
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-2 text-center">
          <button type="submit" class="btn btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <b-table class="table table-hover"
     :fields="fields"
     :items="getlistaFamiliares">
    </b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapMutations } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'Familiar',
  components: {
    Multiselect,
  },
  data() {
    return {
      pacienteSelected: null,
      parentescoSelected: 1,
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '5%' } },
        {
          key: 'numero_exp', label: 'N° Exp', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'parentesco', label: 'Parentesco', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'celular', label: 'Celular', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'edad', label: 'Edad', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'email', label: 'Email', class: 'text-center', thStyle: { width: '20%' },
        },
      ],
    };
  },
  beforeDestroy() {
    this.setlistaFamiliares([]);
  },
  methods: {
    resetearInputPaciente() {
      this.pacienteSelected = null;
    },
    resetearComboParentesco() {
      this.parentescoSelected = 1;
    },
    agregarItem() {
      if (this.pacienteSelected === null) {
        util.showNotify('Elije un Paciente ', 'warn');
        return;
      }
      const numero = this.getlistaFamiliares.length + 1;
      const item = {
        numero,
        id: 0,
        numero_exp: this.pacienteSelected.numero,
        nombre: this.pacienteSelected.full_name,
        parentesco: this.getParentescos[this.parentescoSelected - 1].text,
        celular: this.pacienteSelected.celular,
        edad: this.pacienteSelected.edad,
        email: this.pacienteSelected.email,
        paciente_familiar_id: this.pacienteSelected.id,
        parentesco_id: this.parentescoSelected,
        estado: 'true',
      };
      this.addItemlistaFamiliares(item);
      this.resetearInputPaciente();
      this.resetearComboParentesco();
    },
    ...mapMutations('familiar', ['setlistaFamiliares', 'addItemlistaFamiliares']),
  },
  computed: {
    pacientes() {
      return this.getPacientes.filter((paciente) => (
        !this.getlistaFamiliares.some((item) => item.paciente_familiar_id === paciente.id)
      ));
    },
    ...mapGetters('familiar', ['getPacientes', 'getlistaFamiliares']),
    ...mapGetters('parentescos', ['getParentescos']),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
