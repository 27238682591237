<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-3 mb-3 border border-secondary">
        <div class="col-sm-5">
          <div class="row">
            <div class="col-sm-4 pr-0 text-right">
              <multiselect2
                v-model="typeDocument"
                track-by="abbreviation"
                label="abbreviation"
                placeholder="Tipo Documento"
                :options="typeDocuments"
                @remove="onRemoveTypeDocument"
                :allow-empty="true">
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.abbreviation }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.abbreviation }} - <span style="color: red; font-weight: bold">{{ props.option.description }}</span>
                </template>
              </multiselect2>
            </div>
            <div class="col-sm-4">
              <input type="text"
                     v-model="nit"
                     class="form-control"
                     autocomplete="off"
                     @keydown="validarCaracter"
              >
            </div>
            <div class="col-sm-2">
              <input type="text"
                     v-model="complement"
                     class="form-control"
                     autocomplete="off"
              >
            </div>
          </div>
        </div>
<!--        <div class="col-sm-1"></div>-->
        <div class="col-sm-5">
          <div class="row">
            <div class="col-sm-3 pr-0 text-right">
              <label class="col-form-label">Razón Social:</label>
            </div>
            <div class="col-sm-9">
              <input type="text" v-model="razonSocial" class="form-control" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <button type="submit" class="btn btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <b-table
      class="table table-hover"
      :fields="fields"
      :items="getlistaDatosFactura"
    ></b-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Multiselect2 from '@/components/util/vue-multiselect2';
import TypeDocument from '@/modules/paciente/Enums/TypeDocument';
import { util } from '../../../plugins/util';

export default {
  name: 'DatosFactura',
  components: {
    Multiselect2,
  },
  data() {
    return {
      razonSocial: '',
      nit: '',
      typeDocument: null,
      complement: null,
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '10%' } },
        {
          key: 'razon_social', label: 'Razón Social', class: 'text-center', thStyle: { width: '40%' },
        },
        {
          key: 'type_document', label: 'Tipo', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'nit', label: 'Nit', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'complement', label: 'Complemento', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
    };
  },
  beforeDestroy() {
    this.setlistaDatosFactura([]);
  },
  mounted() {
    this.selectDefaultTypeDocument();
  },
  methods: {
    agregarItem() {
      this.razonSocial = this.razonSocial.trim();
      if (!this.typeDocument) {
        util.showNotify('Seleccione tipo de documento.', 'warn');
        return;
      }
      if (this.razonSocial === '') {
        util.showNotify('Escribe una Razon Social', 'warn');
        return;
      }
      this.nit = this.nit.trim();
      if (this.nit === '') {
        util.showNotify(`Escribe ${this.typeDocument.abbreviation}`, 'warn');
        return;
      }
      const numero = this.getlistaDatosFactura.length + 1;
      const item = {
        numero,
        id: 0,
        razon_social: this.razonSocial,
        nit: this.nit,
        type_document_id: this.typeDocument.id,
        type_document: this.typeDocument.abbreviation,
        complement: this.complement,
        estado: 1,
      };
      this.addItemlistaDatos(item);
      this.resetearInputRazonSocial();
      this.resetearInputNit();
      this.resetForm();
    },
    selectDefaultTypeDocument() {
      // eslint-disable-next-line radix
      this.typeDocument = this.typeDocuments.find((opt) => parseInt(opt.id) === TypeDocument.NIT);
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    onRemoveTypeDocument() {
      this.typeDocument = null;
      this.complement = null;
    },
    resetearInputRazonSocial() {
      this.razonSocial = '';
    },
    resetearInputNit() {
      this.nit = '';
    },
    resetForm() {
      this.typeDocument = null;
      this.complement = null;
      this.selectDefaultTypeDocument();
    },
    ...mapMutations('datosFactura', ['setlistaDatosFactura', 'addItemlistaDatos']),
  },
  computed: {
    ...mapGetters('datosFactura', ['getlistaDatosFactura']),
    ...mapState('datosFactura', ['typeDocuments']),
  },
};
</script>

<style scoped>
</style>
