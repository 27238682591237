<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-2 mb-3 border border-secondary">
        <div class="col-sm-3">
          <div class="form-group">
            <label>Aseguradora</label>
            <multiselect v-model="aseguradoraSelected"
              :options="aseguradoras"
              placeholder=""
              label="nombre" track-by="nombre"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>N° de Poliza:</label>
            <input type="text" v-model="numeroPoliza"
             class="form-control" @keydown="validarCaracter" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Cod. Asegurado:</label>
            <input type="text" v-model="codigoAsegurado"
             class="form-control" @keydown="validarCaracter" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Fecha Venc.</label>
            <date-picker
              class="col-sm-10 px-0"
              v-model="fechaVencimiento"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            ></date-picker>
          </div>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 pt-3">
          <button type="submit" class="btn btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <div class="separator-breadcrumb border-top"></div>
    <b-table class="table table-hover" :fields="fields" :items="getlistaSeguros"></b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import { mapMutations, mapGetters } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'SeguroMedico',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      fechaVencimiento: new Date(),
      aseguradoraSelected: null,
      numeroPoliza: '',
      codigoAsegurado: '',
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '10%' } },
        {
          key: 'aseguradora', label: 'Aseguradora', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'numero_poliza', label: 'N° de Poliza', class: 'text-center', thStyle: { width: '22%' },
        },
        {
          key: 'codigo_aseguradora', label: 'Cod. de Asegurado', class: 'text-center', thStyle: { width: '22%' },
        },
        {
          key: 'fecha_venc', label: 'Fecha de Vencimiento', class: 'text-center', thStyle: { width: '21%' },
        },
      ],
    };
  },
  beforeDestroy() {
    this.setListaSeguros([]);
  },
  methods: {
    validarCaracter(event) {
      const validKeys = new RegExp('^[ñÑa-zA-Z0-9-]*$');
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    resetearInputAseguradora() {
      this.aseguradoraSelected = null;
    },
    resetearInputNumeroPoliza() {
      this.numeroPoliza = '';
    },
    resetearInputCodigoAsegurado() {
      this.codigoAsegurado = '';
    },
    resetearInputFechaVencimiento() {
      this.fechaVencimiento = new Date();
    },
    agregarItem() {
      if (this.aseguradoraSelected === null) {
        util.showNotify('Elije una Aseguradora', 'warn');
        return;
      }
      this.numeroPoliza = this.numeroPoliza.trim();
      if (this.numeroPoliza === '') {
        util.showNotify('Escribe un Numero de Poliza', 'warn');
        return;
      }
      this.codigoAsegurado = this.codigoAsegurado.trim();
      if (this.codigoAsegurado === '') {
        util.showNotify('Escribe un Codigo de Asegurado', 'warn');
        return;
      }
      if (this.fechaVencimiento === null) {
        util.showNotify('Escribe una fecha de Vencimiento Valida', 'warn');
        return;
      }
      const numero = this.getlistaSeguros.length + 1;
      const item = {
        numero,
        id: 0,
        aseguradora: this.aseguradoraSelected.nombre,
        fecha_venc: moment(this.fechaVencimiento).format('DD/MM/YYYY'),
        aseguradora_id: this.aseguradoraSelected.id,
        numero_poliza: this.numeroPoliza,
        codigo_aseguradora: this.codigoAsegurado,
        fecha_vencimiento: moment(this.fechaVencimiento).format('YYYY-MM-DD'),
        estado: '1',
      };
      this.addItemListaSeguros(item);
      this.resetearInputAseguradora();
      this.resetearInputNumeroPoliza();
      this.resetearInputCodigoAsegurado();
      this.resetearInputFechaVencimiento();
    },
    ...mapMutations('seguroMedico', ['setListaSeguros', 'addItemListaSeguros']),
  },
  computed: {
    aseguradoras() {
      return this.getAseguradoras.filter((aseguradora) => (
        !this.getlistaSeguros.some((item) => item.aseguradora_id === aseguradora.id)
      ));
    },
    ...mapGetters('aseguradoras', ['getAseguradoras']),
    ...mapGetters('seguroMedico', ['getlistaSeguros']),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
